<template>
	<div>
		<frame :top1="top1" :top2="top2">
			<!-- 头部 -->

			<!-- 筛选部分 -->
			<div class="screen">
				<div style="height: 40px;text-align: left;padding-left: 40px;padding-top: 10px;">促销>秒杀活动列表</div>
				<div class="screen_cont" style="display: flex;justify-content: space-between;">
						
					
					<el-form size="small" :inline="true" class="demo-form-inline" style="padding-left: 30px;">
						
						<el-form-item label="选择商户：" v-show="merchantShow" >
							<el-select v-model="business_id" placeholder="请选择商户" @change="changeMerchant(business_id)">
								<el-option v-for="item in businessList" :key="item.business_id"
									:label="item.business_name" :value="item.business_id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="banner名称：">
							<el-input v-model="advert_name" placeholder="banner名称"></el-input>
						</el-form-item>

						<el-form-item>
							<el-button @click="screen()" type="danger" size="mini">查询结果</el-button>
						</el-form-item>
						


					</el-form>
					<el-form style="padding-right: 30px;margin-bottom: 50px;">
						<el-form-item style="margin-top: -5px;">
							<el-button size="medium" type="danger"  @click="addAdver"> 添加banner </el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>

			<!-- 表格头部 -->
			<!-- 	<div class="table_head" v-show="allData">
				<div class="table_head_left">
					<i class="el-icon-s-unfold"></i>
					数据列表
				</div>
				<div class="table_head_right">
					<ul>
						<li>
							<el-button size="mini" @click="addAdver"> 添加banner </el-button>
						</li>
					</ul>
				</div>
			</div> -->

			<!-- 表格渲染 -->
			<div class="table" >
				<!-- 设置表格高度  height="500" -->
				<div class="bck" v-show="allData">
					<el-table :row-class-name="tableRowClassName" :data="tableData" border
						style="width: 95%;margin-left: 2.5%; text-align: center;">
						<el-table-column prop="id" label="编号" align="center">
						</el-table-column>

						<el-table-column prop="advert_name" label="banner名称" align="center">
						</el-table-column>

						<el-table-column prop="advert_img" label="banner图片" align="center">
							<template slot-scope="scope">
								<img v-bind:src="scope.row.advert_img" style="width: 100px; height: 100px" />
							</template>
						</el-table-column>

						<el-table-column prop="operation" label="操作" width="180" align="center">
							<template slot-scope="scope">
								<!-- <span style="cursor: pointer" @click="see()">查看</span> -->
								<span style="cursor: pointer"  @click="
                  edit(
                    
                    scope.row.id,
                    scope.row.advert_name,
                    scope.row.advert_img,
                    scope.row.store_id,
                    
                  )
                ">
									编辑
								</span>
								<span v-show="storeShow" style="margin-left: 10px; cursor: pointer"
									@click="deleteAdver(scope.row.id)">
									删除</span>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<div class="block" >
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="activeListTotal">
						</el-pagination>
					</div>
				</div>
		</frame>
	</div>

	</div>
</template>

<script>
	import frame from "../../public/Frame.vue";

	export default {
		data() {
			return {
				top1: "10-1",
				top2: ["10"],
				// flag: 1,
				allData: false, //渲染数据的展示
				advert_name: "", //广告名称
				advert_position: "", //	广告位置
				advert_position_list: [], //广告位置列表
				end_time: "", //结束时间
				// storeid: "", //门店id
				businessList: [{}], //商户列表
				business_id: "", //选中的商户ID
				storeList: [{}], //门店列表
				storeid: "", //选中的门店ID

				admin_type: "",
				coupon_name: "", //活动名称搜索的input框的值
				active_status: "", //选择活动状态
				merchant_status: "", //选择商户
				merchantShow: false, //商户个别权限的隐藏
				storeShow: false, //门店权限的隐藏
				show: false,
				selectGoodsListShow: false, //指定商品的显示隐藏
				goodsListShow: false,
				cur_page: 0, //设置一个默认值
				addTableData: [], //优惠券商品列表
				goodsListTotal: 10, //优惠商品总条数
				tableData: [], //表格渲染数据
				coupon_id: "", //编辑时的优惠券id
				activeListTotal: 10, //表格总条数
				searchIpt: "", //优惠商品列表搜索值
				dataSelections: "", //选中的商品列表数据
				selectTableData: [], //指定商品的商品数据
				goods_type: "", //指定商品还是全部商品类型
				//总页数
				currentPage: 1,
				pageSize: 10,
				value2: true,
			};
		},
		methods: {
			//隔行变色
			tableRowClassName({
				row,
				rowIndex,
			}) {
				if (rowIndex % 2 == 0) {
					return 'warning-row';
				} else if (rowIndex === 3) {
					return 'success-row';
				}
				return '';
			},


			//选中商户查询门店
			changeMerchant(business_id) {
				console.log(business_id);
				this.$request
					.businessStoreList({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal("type"), //身份
						business_id: business_id,
					})
					.then((res) => {
						console.log(res);
						this.storeList = res.data;
					});
			},
			//广告列表查询按钮
			screen() {
				console.log(
					/* this.advert_name,
					this.advert_position,
					this.end_time, */
					// this.storeid,
					// this.storeShow
				);

				this.$request
					.advertList({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal("type"), //身份
						advert_name: this.advert_name, //	是	string	广告名称
						// advert_position: this.advert_position, //	是	string	广告位置
						// end_time: "", //	是	string	结束时间
						store_id: this.business_id,
						// store_id: this.storeid, //	是	int	门店id
						limit: 10, //	是	int	一页显示几条数据默认10
						page: 1, //	是	int	第几页默认1
					})
					.then((res) => {
						console.log(res);
						this.allData = true;
						this.tableData = res.data.data;
						this.activeListTotal = res.data.count;
					});
			},

			//刷新页面按钮
			refresh() {
				location.reload();
			},
			//添加活动按钮(跳转到添加优惠券)
			addAdver() {
				this.$router.push({
					path: "/addAdvertising"
				});
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = parseInt(`${val}`);

				this.$request
					.advertList({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal("type"), //身份
						advert_name: this.advert_name, //	是	string	广告名称
						advert_position: this.advert_position, //	是	string	广告位置
						end_time: "", //	是	string	结束时间
						business_id: this.business_id,
						store_id: this.storeid, //	是	int	门店id
						limit: this.pageSize, //	是	int	一页显示几条数据默认10
						page: this.currentPage, //	是	int	第几页默认1
					})
					.then((res) => {
						console.log(res);
						this.allData = true;
						this.tableData = res.data.data;
						this.activeListTotal = res.data.count;
					});
			},

			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = parseInt(`${val}`);
				console.log(1111111111111111111, this.currentPage);
				this.$request
					.advertList({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal("type"), //身份
						advert_name: this.advert_name, //	是	string	广告名称
						advert_position: this.advert_position, //	是	string	广告位置
						end_time: "", //	是	string	结束时间
						business_id: this.business_id,
						store_id: this.storeid, //	是	int	门店id
						limit: this.pageSize, //	是	int	一页显示几条数据默认10
						page: this.currentPage, //	是	int	第几页默认1
					})
					.then((res) => {
						console.log(res);
						this.allData = true;
						this.tableData = res.data.data;
						this.activeListTotal = res.data.count;
					});
			},
			//修改上下架
			switchChange(is_online, id) {
				console.log(is_online, id);
				this.$request
					.updateAdvert({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal(
							"type"), //身份          advert_name: this.advert_name, //	string	广告名称
						advert_name: this.advert_name, //广告名
						advert_position: this.value, //	string	广告位置
						start_time: this.start_time, //	string	开始时间例2021-01-01
						end_time: this.end_time, //	string	结束时间
						is_online: is_online, //	int	1上线2不上线
						advert_img: this.advert_img, //	string	广告图片
						advert_link: this.advert_link, //	string	广告链接
						advert_remark: this.advert_remark, //		string	广告备注
						store_id: this.storeid, //	int	门店id
						advert_id: id, //广告id
					})
					.then((res) => {
						console.log(res);
						if (res.code == 0) {
							this.$request
								.advertList({
									token: this.$storage.getLocal("token"), //登陆进来的tooken
									admin_type: this.$storage.getLocal("type"), //身份
									advert_name: this.advert_name, //	是	string	广告名称
									advert_position: this.advert_position, //	是	string	广告位置
									end_time: "", //	是	string	结束时间
									business_id: this.business_id,
									store_id: this.storeid, //	是	int	门店id
									limit: this.pageSize, //	是	int	一页显示几条数据默认10
									page: this.currentPage, //	是	int	第几页默认1
								})
								.then((res) => {
									console.log(res);
									this.allData = true;
									this.tableData = res.data.data;
									this.activeListTotal = res.data.count;
								});
						} else {
							alert("请求失败");
						}
					});
			},

			//编辑商品按钮
			edit(
				id,
				advert_name,
				advert_position,
				start_time,
				end_time,
				is_online,
				advert_img,
				advert_link,
				advert_remark,
				store_id
			) {
				console.log(
					"编辑商品的默认值",

					id,
					advert_name,
					advert_position,
					start_time,
					end_time,
					is_online,
					advert_img,
					advert_link,
					advert_remark,
					store_id,
				);
				this.$router.push({
					path: "/addAdvertising",
					query: {
						id,
						advert_name,
						advert_position,
						start_time,
						end_time,
						is_online,
						advert_img,
						advert_link,
						advert_remark,
						store_id
					},
				});
			},

			//关闭添加商品窗口
			closeAdd() {
				this.goodsListShow = false;
				this.selectGoodsListShow = false;
			},

			//删除广告
			deleteAdver(id) {
				console.log(id);
				// console.log(typeof coupon_id);
				this.$request
					.deleteAdvert({
						token: this.$storage.getLocal("token"),
						advert_id: id,
					})
					.then((res) => {
						console.log(res);
						this.$request
							.advertList({
								token: this.$storage.getLocal("token"), //登陆进来的tooken
								admin_type: this.$storage.getLocal("type"), //身份
								advert_name: this.advert_name, //	是	string	广告名称
								advert_position: this.advert_position, //	是	string	广告位置
								end_time: "", //	是	string	结束时间
								business_id: this.business_id,
								store_id: this.storeid, //	是	int	门店id
								limit: this.currentPage, //	是	int	一页显示几条数据默认10
								page: this.pageSize, //	是	int	第几页默认1
							})
							.then((res) => {
								console.log(res);
								this.allData = true;
								this.tableData = res.data.data;
								this.activeListTotal = res.data.count;
							});
					});
			},
		},

		created() {
			//请求商户列表接口
			this.$request.businessList({}).then((res) => {
				console.log(res);
				this.businessList = res.data;
			});
			console.log("11111", this.businessList);
			//请求广告位置接口
			this.$request.advertPosition({}).then((res) => {
				console.log(res);

				if (res.code == 0) {
					this.advert_position_list = res.data;
				} else {
					alert("广告位置接口没有请求到");
				}
			});
			//不同权限下的商户显示
			this.admin_type = this.$storage.getLocal("type");
			console.log("business_id", this.business_id);
			if (this.admin_type == 1) {
				this.merchantShow = true;
				this.storeShow = true;
			}
			if (this.admin_type == 2) {
				this.$request
					.advertList({
						token: this.$storage.getLocal("token"), //登陆进来的tooken
						admin_type: this.$storage.getLocal("type"), //身份
						advert_name: this.advert_name, //	是	string	广告名称
						// advert_position: this.advert_position, //	是	string	广告位置
						// end_time: "", //	是	string	结束时间
						store_id: this.business_id,
						// store_id: this.storeid, //	是	int	门店id
						limit: 10, //	是	int	一页显示几条数据默认10
						page: 1, //	是	int	第几页默认1
					})
					.then((res) => {
						console.log(res);
						this.allData = true;
						this.tableData = res.data.data;
						this.activeListTotal = res.data.count;
					});
				// this.merchantShow = true;
				this.storeShow = true;
				//   this.$request
				//   .businessStoreList({
				//     token: this.$storage.getLocal("token"), //登陆进来的tooken
				//     admin_type: this.$storage.getLocal("type"), //身份
				//     // business_id: business_id,
				//   })
				//   .then((res) => {
				//     console.log("帅气的我",res);
				//     // this.ruleForm.storeList = res.data;
				//       this.storeList = res.data;

				//   });
			}
		},
		components: {
			frame,
		},
	};
</script>

<style scoped>
	.head {
		width: 100%;
		height: 45px;
		line-height: 45px;
		background-color: rgb(243, 243, 243);
	}

	.left,
	.right {
		display: inline;
	}

	.left {
		float: left;
		margin-left: 210px;
	}

	.left_tit {
		border-left: 5px solid red;
		font-size: 22px;
		padding: 5px;
	}

	.refresh {
		float: right;
		margin-right: 10%;
		margin-top: 2px;
	}

	.screen {
		width: 87%;
		margin-left: 200px;
		height: 120px;
		background-color: rgb(245, 247, 249);
	}

	.screen_tit {
		height: 40px;
		background-color: rgb(243, 243, 243);
	}

	.screen_left {
		float: left;
		margin-left: 50px;
		margin-top: 10px;
	}

	.screen_right {
		float: right;
		margin-top: 6px;
		margin-right: 10px;
	}

	.screen_cont {
		margin-left: 3%;
		font-size: 18px;
		/* border: 1px solid red; */
		background-color: white;
		border-radius: 5px;
		/* margin-top: 50px; */
		height: 60px;
		padding-top: 15px;
		width: 94%;
	}

	.popContainer {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
		z-index: 3;
	}

	.transition-box {
		position: absolute;
		z-index: 2;
		top: 150px;
		left: 27%;
		width: 800px;
		height: 580px;

		box-shadow: 0px 0px 10px #888888;
		border-radius: 4px;
		background-color: #fff;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
	}
/deep/ [data-v-0967630c] tr
{
	pointer-events:auto ;
}
	.act_tit {
		width: 800px;
		height: 50px;
		background-color: rgb(236, 128, 141);
		margin-top: -40px;
		margin-left: -20px;
		margin-bottom: 50px;
		font-size: 20px;
		line-height: 50px;
		display: flex;
		justify-content: space-between;
	}

	.table_head {
		height: 40px;
		margin-left: 200px;
		width: 85%;
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-between;
		padding-top: 5px;
	}

	.table_head_left {
		margin-top: 5px;
		margin-left: 20px;
	}

	.table_head_right li {
		float: right;
		height: 30px;

		list-style: none;
		margin-right: 10px;
	}

	.block {
		margin-top: 30px;
	}

	/deep/ .el-table .warning-row {
		background: rgb(250, 247, 245);
		/* background-color: red !important; */
	}

	/deep/ tr {
		pointer-events: none;
	}

	.table {
		background-color: rgb(245, 247, 249);
		padding-top: 10px;
		padding-bottom: 25px;
		padding-left: 14%;
		/* width: 80%; */
		min-height: 75vh;

	}

	.bck {
		width: 95%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 2%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
</style>
